.alert-green {
  background: rgba(0, 255, 0, 0.3);
}
.alert-yellow {
  background: rgba(255, 255, 0, 0.3);
}
.alert-red {
  background: rgba(255, 0, 0, 0.3);
}

.MuiTableRow-head > .MuiTableCell-head {
  font-weight: bold;
}
